<template>
	<FooterBar
		hide-social-media-links
		hide-sitemap-link
		hide-logo
		:version="version"
		:cookies-route="cookiesPageRoute"
	/>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { themeColors } from '@/theme/colors';
	import { IMAGES } from '@/constants/image';
	import AppImage from '@/components/image/image.vue';
	import FooterBar from '@cnamts/vue-dot/src/patterns/FooterBar';

	/** The application footer */
	@Component({
		components: {
			AppImage,
			FooterBar
		}
	})
	export default class AppFooter extends Vue {

		get themeColors() {
			return themeColors;
		}

		get imageUrl() {
			return {
				arrowUpImage: IMAGES.ARROWUP
			};
		}

		cookiesPageRoute = 'cookiesPage';

		mounted() {
			const lienGestionCookies = document.querySelector('a[href="/cookiesPage"]');
			this.cookiesPageRoute = '';
			lienGestionCookies?.addEventListener('click', this.handleCookieLinkClick);
		}

		handleCookieLinkClick() {
			this.$emit('show-cookies-page');
		}

		version = process.env.VUE_APP_VERSION;
	}
</script>
<style lang="scss" scoped>
	.arrow-up {
		position: absolute;
		right: 32px;
		top: 28px;
		width: 16px;
		height: 16px;
	}
@media (min-width: 300px) {
	.links {
		display: grid;
		text-align: left;
		justify-content: left;
	}
}
@media (min-width: 768px) {
	.links {
		display: flex;
		text-align: center;
		justify-content: center;
	}
}
</style>
