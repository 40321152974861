<template>
	<!-- VApp is an element which is required to use Vuetify. See https://vuetifyjs.com/en/ for help -->
	<VApp v-cloak>
		<AppHeader id="go-top-page" />

		<NotificationBar
			v-show="notification"
			data-cy="notificationBar"
			:bottom="isMobile()"
		/>

		<VMain>
			<!-- Transition between routes -->
			<transition
				name="fade"
				mode="out-in"
			>
				<!-- Load the component defined in the router for the current route -->
				<RouterView />
			</transition>
		</VMain>
		<AppFooter
			v-if="!$maintenanceEnabled"
			@show-cookies-page="openCookieBanner"
		/>
		<div
			v-if="isMobile() && isHomePage()"
			style="min-height: 75px;"
		/>

		<v-overlay
			v-if="!$maintenanceEnabled"
			:value="activeCookieBanner || activeCookiePage"
			:dark="false"
		>
			<CookieBanner
				v-if="activeCookieBanner"
				data-cy="cookieBanner"
				cookies-route="/"
				@reject="saveRequiredCookie('false')"
				@accept="saveRequiredCookie('true')"
			/>
			<CookiesPage
				v-if="activeCookiePage"
				data-cy="cookiesPage"
				:items="cookiesList"
				@submit="savePreferences"
			>
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template #cookie-description-Player="{ cookie }">
					<p class="vd-cookie-description-Player">
						{{ cookiesList.functional[0].description }}
						<a
							href="https://vimeo.com/cookie_policy"
							target="_blank"
						>
							https://vimeo.com/cookie_policy
						</a>
					</p>
				</template>
			</CookiesPage>
		</v-overlay>
	</VApp>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import { Meta, MetaInfo } from '@/decorators';

	// Static import for faster rendering
	import { AppHeader, AppFooter } from '@/components/layout';
	import NotificationBar from '@cnamts/vue-dot/src/patterns/NotificationBar/NotificationBar.vue';
	import { mapActions, mapState } from 'vuex';
	import { Watch } from 'vue-property-decorator';
	import CookieBanner from '@cnamts/vue-dot/src/patterns/CookieBanner/CookieBanner.vue';
	import CookiesPage from '@cnamts/vue-dot/src/templates/CookiesPage/CookiesPage.vue';

	/** App is the main component */
	@Component({
		components: {
			AppHeader,
			AppFooter,
			NotificationBar,
			CookiesPage,
			CookieBanner
		},
		computed: {
			...mapState('notification', ['notification']),
			...mapState('cookie', [
				'activeCookieBanner',
				'activeCookiePage',
				'activeVideoPage',
				'cookiesList'
			])
		},
		methods: {
			...mapActions('notification', ['clearNotification']),
			...mapActions('cookie', ['initCookieState', 'saveRequiredCookie', 'savePreferences', 'openCookieBanner'])
		}
	})
	export default class App extends Vue {
		initCookieState!: () => void;
		cookiesList!: object;
		/** The meta informations of the route */
		/* istanbul ignore next */
		@Meta
		metaInfo(): MetaInfo {
			return {
				// If no sub-components specify a metaInfo.title, this title will be used
				title: 'Mon test IST',
				// All titles will be injected into this template
				titleTemplate: '%s | CNAM',
				meta: [
					{
						name: 'description',
						vmid: 'description', /** Used to avoid duplicated tags @see https://vue-meta.nuxtjs.org/faq/#unique-metadata */
						content: 'Mon test IST.'
					}
				]
			};
		}
		@Watch('notification')
		clearNotif() {
			if(this.notification && this.notification.type !== 'error') {
				setTimeout(() => {
					this.clearNotification();
				}, 5000);
			}
		}
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		}

		created() {
			this.initCookieState();

		}

		mounted() {
			this.customizeCookieBanner();
		}

		updated() {
			this.customizeCookieBanner();
		}

		customizeCookieBanner() {
			const lienChoix = document.querySelector('.vd-cookie-banner-action-ctn .v-btn--router');
			lienChoix?.addEventListener('click', this.handleCookieBannerClick);
			document.querySelector('[aria-label="Continuer sans accepter"]')?.remove();

		}

		handleCookieBannerClick(event: Event) {
			event.preventDefault();
			event.stopImmediatePropagation();
			this.$store.commit('cookie/SET_ACTIVE_COOKIE_PAGE', true);
		}

		isHomePage() {
			return this.$route.path === '/';
		}
	}
</script>

<style lang="scss" scoped>
	.vd-cookie-banner {
		position: fixed;
		bottom: 10px;
		z-index: 1;
		max-width: 88vw !important;
	}

	.vd-page-container {
		max-width: 88vw !important;
		max-height: 95vh !important;
		overflow-y: auto;
	}

	.vd-cookie-description-Player {
		font-size: 0.875rem;
	}
</style>
