// 📦 Importations des modules Vue et décorateurs des icônes
import Vue from 'vue';
import Component from 'vue-class-component';
import { mdiHome, mdiFlaskEmpty, mdiInformationOutline, mdiEyeOffOutline } from '@mdi/js';
// 🛠️ Types et constantes
import { ImageUrl } from '@/types';
import { IMAGES } from '@/constants/image';
// 📌 Importation des composants enfants
import CTypography from '@/components/home/component/CTypography.vue';
import AppImage from '@/components/image/image.vue';
import CBadge from '@/components/home/component/CBadge.vue';
import ICard from '@/components/home/component/ICard.vue';
import { mapActions, mapState } from 'vuex';
import VideoTranscription from '@/components/home/sections/header/components/VideoTranscription.vue';

@Component({
	components: {
		VideoTranscription,
		ICard,
		CBadge,
		AppImage,
		CTypography
	},
	computed: {
		...mapState('cookie', [
			'activeCookieBanner',
			'activeCookiePage',
			'activeVideoPage',
			'cookiesList'
		])
	},
	methods: {
		...mapActions('cookie', ['setVideoCookie'])
	}
})
export default class S_Header extends Vue {
	setVideoCookie!: () => void;
	icons = {
		home: mdiHome,
		flaskEmpty: mdiFlaskEmpty,
		informationOutline: mdiInformationOutline,
		hide: mdiEyeOffOutline
	};

	showCards = false;
	private readonly VIDEO_ID: string = '1026957104';
	showVideoTranscription = false;

	mounted() {
		setTimeout(() => {
			this.showCards = true;
		}, 50);
	}

	/**
	 * 🔗 Génération de l'URL de la vidéo
	 * @returns Lien de la vidéo Vimeo
	 */
	videoUrl(): string {
		return `https://player.vimeo.com/video/${this.VIDEO_ID}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
	}

	/**
	 * 🖼️ Récupération de l'URL de l'image
	 * @returns URL de l'image de coordImage
	 */
	get imageUrl(): ImageUrl {
		return {
			coordImage: IMAGES.LOGO_IST
		};
	}

	handleActiveCookiesVimeo(): void {
		this.setVideoCookie();
	}

	handleToggleTranscription(): void {
		this.showVideoTranscription = !this.showVideoTranscription;
		const transcriptionElement = this.$refs.transcriptionMainPage as HTMLElement;
		const videoElement = this.$refs.mainPageVideo as HTMLElement;
		if (this.showVideoTranscription) {
			this.scrollToVideo(videoElement);
			transcriptionElement.style.height = `${transcriptionElement.scrollHeight}px`;
		} else {
			this.scrollToVideo(videoElement);
			setTimeout(() => {
				transcriptionElement.style.height = '0px';
			}, 260);
		}
	}

	private scrollToVideo(videoElement: HTMLElement) {
		const elementRect = videoElement.getBoundingClientRect();
		const targetPosition = elementRect.top + document.documentElement.scrollTop - 30;
		videoElement.scrollIntoView({ behavior: 'smooth' });
		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth'
		});
	}
}
