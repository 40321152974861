/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosResponse } from 'axios';
import { store } from '@/store';
import { API_ADDRESS_URL, API_HOST, GRAVITEE_APIKEY } from '@/constants';

/** Notification Bar */
const ADD_NOTIFICATION_EVENT = 'notification/add';

/** The axios instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: API_HOST,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'x-api-key': GRAVITEE_APIKEY
	}
});

/** The axios instance for Apis Tiers (without Header) */
const instanceApiTiers = axios.create({
	withCredentials: false,
	baseURL: API_ADDRESS_URL
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const problems = error.response?.data?.problems;
		if (problems?.length > 0 && problems[0]?.code) {
			playNotificationGenericError(
				problems[0]?.message || 'Une erreur inconnue est survenue. Veuillez réessayer plus tard.'
			);
		} else {
			playNotificationGenericError(
				'Une erreur inconnue est survenue. Veuillez réessayer plus tard.'
			);
		}
		return Promise.reject(error);
	}
);

function playNotificationGenericError(message: string): void {
	store.dispatch(ADD_NOTIFICATION_EVENT, {
		message,
		type: 'error'
	});
}

export { instance as axios, instanceApiTiers as axiosApiTiers };
export { AxiosResponse };
