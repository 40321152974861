<template>
	<div class="header">
		<div class="header__background-effect be-pink" />
		<div class="header__background-effect be-green" />
		<PageContainer size="xl">
			<div class="header__content">
				<div class="header__introduction">
					<AppImage
						class="header__logo"
						:image="imageUrl.coordImage"
					/>
					<!-- eslint-disable-next-line -->
					<CTypography tag="h1" class="header__title">
						Faites le test, c’est rapide et offert
					</CTypography>
					<CTypography tag="h4">
						Une infection sexuellement transmissible (IST), ça ne se voit pas toujours. Même sans symptôme, vous
						pouvez être porteur d’une IST, et la transmettre. C’est pourquoi entre 18 et 25 ans l’Assurance
						Maladie vous offre, <span class="header__highlight">sans ordonnance</span>, un dépistage de
						certaines IST.
					</CTypography>
				</div>
				<div>
					<!-- eslint-disable-next-line -->
					<CTypography tag="h2" class="header__subtitle">
						Le test est réalisable de deux façons
					</CTypography>
					<div class="header__cards">
						<ICard
							transition-name="i-card-one"
							:show="showCards"
							class="header__card"
						>
							<CBadge class="header__badge">
								<VIcon class="header__badge__icon">
									{{ icons.home }}
								</VIcon>
								<CTypography tag="h4">
									Kit à domicile
								</CTypography>
							</CBadge>
							<CTypography tag="h4">
								Suite à la commande sur ce site d’un kit de dépistage <span class="header__information-word">par auto-prélèvement</span> de deux IST :
								les infections à <span class="header__information-word">Chlamydia</span> et à <span class="header__information-word">gonocoque</span>.
							</CTypography>
						</ICard>
						<ICard
							transition-name="i-card-two"
							:show="showCards"
							class="header__card"
						>
							<CBadge class="header__badge">
								<VIcon class="header__badge__icon">
									{{ icons.flaskEmpty }}
								</VIcon>
								<CTypography tag="h4">
									En laboratoire
								</CTypography>
							</CBadge>
							<CTypography tag="h4">
								Sans rendez-vous, pour le dépistage des infections à <span class="header__information-word">Chlamydia</span> et <span class="header__information-word">gonocoque</span> mais aussi du <span class="header__information-word">VIH/sida</span>, de la <span class="header__information-word">syphilis</span> et de l’<span class="header__information-word">hépatite B</span>.
							</CTypography>
						</ICard>
					</div>
				</div>
			</div>
		</PageContainer>
		<PageContainer size="m">
			<div class="header__footer">
				<VCard class="pa-2 mt-10 rounded-l header__footer__alert">
					<VIcon class="header__footer__alert__icon">
						{{ icons.informationOutline }}
					</VIcon>
					<CTypography class="header__footer__alert__text">
						Seule la commande des kits de dépistage par auto-prélèvement vaginal à destination des femmes est actuellement possible. Les kits de dépistage par auto-prélèvement urinaire destinés aux hommes ne sont pour l’instant pas disponibles.
					</CTypography>
				</VCard>
				<VCard class="pa-4 mt-5 rounded-xl">
					<template v-if="activeVideoPage === true">
						<div class="header__footer__video-responsive">
							<iframe
								ref="mainPageVideo"
								class="header__footer__video-responsive-item"
								:src="videoUrl()"
								allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
								title="CNAM_IST_241106_1080_BASSEDEF"
							/>
						</div>
						<div>
							<button
								class="mt-4 header__footer__btn-transcription"
								@click="handleToggleTranscription"
							>
								<VIcon class="mr-1 header__footer__btn-transcription__icon">
									{{ icons.hide }}
								</VIcon>
								<CTypography class="header__footer__btn-transcription__text">
									{{ showVideoTranscription ? 'Masquer la transcription textuelle de la vidéo' : 'Lire la transcription textuelle de la vidéo' }}
								</CTypography>
							</button>
							<div
								ref="transcriptionMainPage"
								class="mt-2 collapse header__footer__transcription"
							>
								<VideoTranscription />
								<button
									class="mt-2 header__footer__btn-transcription"
									@click="handleToggleTranscription"
								>
									<VIcon class="mr-1 header__footer__btn-transcription__icon">
										{{ icons.hide }}
									</VIcon>
									<CTypography class="header__footer__btn-transcription__text">
										{{ showVideoTranscription ? 'Masquer la transcription textuelle de la vidéo' : 'Lire la transcription textuelle de la vidéo' }}
									</CTypography>
								</button>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="header__footer__video-disable">
							<div class="header__footer__video-disable__content">
								<p>La vidéo est désactivée.</p>
								<p>Autorisez le dépôt de cookies Vimeo pour accéder à cette fonctionnalité</p>
								<div @click="handleActiveCookiesVimeo">
									Accepter
								</div>
								<p>En savoir plus sur la politique de protection des données personnelles</p>
							</div>
						</div>
					</template>
				</VCard>
			</div>
		</PageContainer>
	</div>
</template>

<script lang="ts">
	import S_Header from './S_Header';
	export default S_Header;
</script>

<style src="./S_Header.scss" lang="scss"></style>
