<template>
	<div class="labo">
		<PageContainer
			size="l"
		>
			<div class="labo__header">
				<div class="labo__header__labels">
					<CTypography class="labo__header__label prescription">
						Sans ordonnance
					</CTypography>
					<CTypography class="labo__header__label appointment">
						Sans rendez-vous
					</CTypography>
					<CTypography class="labo__header__label costs">
						Sans frais
					</CTypography>
				</div>
				<CTypography class="labo__header__title">
					Les laboratoires de biologie médicale proposent de dépister :
				</CTypography>
			</div>

			<div class="labo__content">
				<div class="labo__content__card">
					<div class="labo__content__labels">
						<CTypography class="labo__content__label">
							Chlamydia
						</CTypography>
						<CTypography class="labo__content__label">
							Gonocoque
						</CTypography>
						<CTypography class="labo__content__label">
							Syphilis
						</CTypography>
					</div>
					<div class="labo__content__labels second">
						<CTypography class="labo__content__label">
							VIH / sida
						</CTypography>
						<CTypography class="labo__content__label">
							Hépatite B
						</CTypography>
					</div>
					<CTypography class="labo__content__card__title">
						Vous souhaitez en savoir plus sur les infections sexuellement transmissibles ?
					</CTypography>
					<div class="labo__content__card__cta">
						<VBtn
							class="no-max-width v-btn-ist"
							color="primary"
							append-icon="$vuetify"
							:outlined="true"
							@click="goToAmeli()"
						>
							Rendez-vous sur le site ameli
							<AppImage
								class="labo__content__card__cta__arrow-right"
								:image="imageUrl.arrowUpImage"
							/>
						</VBtn>
					</div>
				</div>
			</div>
			<div class="labo__footer">
				<VBtn
					color="primary"
					class="v-btn-ist"
					@click="goToForm()"
				>
					Trouvez un laboratoire
				</VBtn>
			</div>
		</PageContainer>
	</div>
</template>

<script lang="ts">
	import S_Labo from './S_Labo';
	export default S_Labo;
</script>

<style src="./S_Labo.scss" lang="scss"></style>
