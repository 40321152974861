import { Commit, Module } from 'vuex';
import { RootState } from '@/store/types';
import { CookieState } from '@/store/modules/cookies/types';
import { TypeEnum } from '@cnamts/vue-dot/src/templates/CookiesPage/CookiesInformation/TypeEnum';
import { Preferences } from '@cnamts/vue-dot/src/templates/CookiesPage/types';
import Cookies from 'js-cookie';
import {
	COOKIE_ANALYTICS_DURATION,
	COOKIE_ANALYTICS_UNIT,
	COOKIE_REQUIRED_DURATION,
	COOKIE_REQUIRED_UNIT
} from '@/constants';
import moment, { unitOfTime } from 'moment';

const state: CookieState = {
	activeCookieBanner: false,
	activeCookiePage: false,
	activeVideoPage: false,

	cookiesList: {
		[TypeEnum.ESSENTIALS]: [
			{
				name: 'cookie_agreed',
				description: 'Permet de mémoriser le consentement de bandeau cookies',
				conservation: '13 mois'
			}
		],
		[TypeEnum.ANALYTICS]: [
			{
				name: 'Google Tag Manager : cookies Campaign Manager',
				description:
					'Ces cookies permettent d’établir des statistiques anonymes de nos campagnes de communication, via Google Tag Manager, pour évaluer leur diffusion et leur efficacité. Les données collectées par nos partenaires ne sont utilisées que pour répondre aux besoins de Mon Test IST et ne sont ni stockées ni transmises à des tiers non autorisés.',
				conservation: '13 mois'
			}
		],
		[TypeEnum.FUNCTIONAL]: [
			{
				name: 'Player',
				description:
					`Ces cookies déposés via le service de lecture vidéos Vimeo vous permettent de visionner les
					contenus multimédia. A cette occasion, un transfert de données vers les États-Unis est opéré qui se
					fonde sur les clauses contractuelles types le cas échéant (article 46, paragraphe 2 du Règlement
					général sur la protection des données) approuvées par la Commission Européenne, qui ont pour
					objectif de garantir un niveau adéquat de protection des données personnelles.`,
				conservation: '13 mois'
			}
		]
	}
};

const mutations = {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	SET_ACTIVE_COOKIE_BANNER(state: CookieState, value: boolean) {
		state.activeCookieBanner = value;
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	SET_ACTIVE_COOKIE_PAGE(state: CookieState, value: boolean) {
		state.activeCookiePage = value;
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	SET_ACTIVE_VIDEO_PAGE(state: CookieState, value: boolean) {
		state.activeVideoPage = value;
	}
};

function expirationDate(duration: number, unit: unitOfTime.Base) {
	return moment().add(duration, unit).toDate();
}

const actions = {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	initCookieState({ commit }: { commit: Commit }) {
		const activeCookieBanner = Boolean(!Cookies.get('cookie_agreed'));
		const activeVideoPage = Cookies.get('video_tag') === 'true';
		commit('SET_ACTIVE_COOKIE_BANNER', activeCookieBanner);
		commit('SET_ACTIVE_VIDEO_PAGE', activeVideoPage);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	saveRequiredCookie({ commit }: { commit: Commit }, userChoice: string) {
		Cookies.set('cookie_agreed', 'true', {
			expires: expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base)
		});
		Cookies.set('google_tag', userChoice, {
			expires: expirationDate(Number(COOKIE_ANALYTICS_DURATION), COOKIE_ANALYTICS_UNIT as unitOfTime.Base)
		});
		Cookies.set('video_tag', userChoice, {
			expires: expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base)
		});
		commit('SET_ACTIVE_COOKIE_BANNER', false);
		commit('SET_ACTIVE_COOKIE_PAGE', false);
		commit('SET_ACTIVE_VIDEO_PAGE', userChoice === 'true');
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	savePreferences({ commit }: { commit: Commit }, preferences: Preferences) {
		Cookies.set('cookie_agreed', 'true', {
			expires: expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base)
		});
		Cookies.set('google_tag', String(preferences.analytics), {
			expires: expirationDate(Number(COOKIE_ANALYTICS_DURATION), COOKIE_ANALYTICS_UNIT as unitOfTime.Base)
		});
		Cookies.set('video_tag', String(preferences.functional), {
			expires: expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base)
		});
		commit('SET_ACTIVE_COOKIE_PAGE', false);
		commit('SET_ACTIVE_COOKIE_BANNER', false);
		commit('SET_ACTIVE_VIDEO_PAGE',  preferences.functional);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	openCookieBanner({ commit }: { commit: Commit }) {
		commit('SET_ACTIVE_COOKIE_PAGE', true);
	},

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	setVideoCookie({ commit }: { commit: Commit }) {
		Cookies.set('video_tag', 'true', {
			expires: expirationDate(Number(COOKIE_REQUIRED_DURATION), COOKIE_REQUIRED_UNIT as unitOfTime.Base)
		});
		commit('SET_ACTIVE_VIDEO_PAGE', true);
	}
};

export const cookie: Module<CookieState, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions
};
