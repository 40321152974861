<script lang="ts">
	import { Vue, Component } from 'vue-property-decorator';

	@Component({

	})
	export default class VideoTranscription extends Vue {

	}
</script>

<template>
	<div>
		<p class="font-italic">
			Visuel: Écran vert
		</p>
		<p class="font-italic">
			Musique dynamique pendant toute la vidéo
		</p>
		<p class="font-bold">
			Illustration Logo mon test IST + QUI DIT SEXE, DIT TEST.
		</p>
		<p>
			1 MINUTE POUR COMPRENDRE !
		</p>
		<p class="font-bold">
			Illustration : sablier qui s’écoule pendant toute la vidéo.
		</p>
		<p>
			Parce qu’une infection sexuellement transmissible (IST) ne se voit pas toujours…
		</p>
		<p class="font-bold">
			Illustration : Apparition de 2 virus rose et vert.
		</p>
		<p>
			…même sans symptôme, vous pouvez être porteur et la transmettre.
		</p>
		<p class="font-bold">
			Illustration : Apparition d’une multitude de bonhommes virus, roses et verts partout sur l’écran
		</p>
		<p>
			Faire régulièrement un test c’est important !
		</p>
		<p>
			Vous avez moins de 26 ans ?
		</p>
		<p>
			Avec mon test IST faites-vous dépister des infections sexuellement transmissibles.
		</p>
		<p class="font-bold">
			Illustration : logo mon test IST
		</p>
		<p>
			Depuis chez vous pour les infections à Chlamydia et à gonocoque.
		</p>
		<p class="font-bold">
			Illustration : Un virus vert est posé sur le texte.
		</p>
		<p>
			Un simple kit d’auto-prélèvement suffit.
		</p>
		<p>
			Commandez-le gratuitement depuis le site mon-test-ist.ameli.fr
		</p>
		<p>
			En + des infections à Chlamydia et à gonocoque vous pouvez aussi vous faire dépister : du VIH/sida, de la syphilis et de l’hépatite B.
		</p>
		<p class="font-bold">
			Illustration : Apparition de trois bonhommes virus, vert, bleu et rose.
		</p>
		<p>
			Directement en laboratoire.
		</p>
		<p class="font-bold">
			Illustration : Un bonhomme virus rose passe une porte.
		</p>
		<p>
			Sans ordonnance.
		</p>
		<p>
			Sans rendez-vous.
		</p>
		<p>
			Sans frais.
		</p>
		<p>
			Rappel. Le préservatif est le meilleur moyen de se protéger contre les infections sexuellement transmissibles.
		</p>
		<p class="font-bold">
			Illustration : Logo mon test IST + QUI DIT SEXE, DIT TEST.
		</p>
		<p>
			Logo Assurance Maladie
		</p>
	</div>
</template>

<style scoped lang="scss">
	@use '@/theme/colors.scss' as themeColors;

	p {
		color: themeColors.$BLACK!important;
	}
</style>
