<template>
	<div>
		<S_Header />
		<div class="switch-container">
			<div class="switch">
				<input
					id="laboratoire"
					v-model="selectedOption"
					type="radio"
					value="laboratoire"
				>
				<label
					for="laboratoire"
					class="option"
				>En laboratoire</label>

				<input
					id="kit"
					v-model="selectedOption"
					type="radio"
					value="kit"
				>
				<label
					for="kit"
					class="option"
				>Kit à domicile</label>

				<span class="slider" />
			</div>
		</div>
		<div class="home">
			<div class="home__content">
				<div class="home__alert">
					<AppImage
						class="home__alert__img"
						:image="imageUrl.warningImage"
					/>
					<span>En cas de symptômes, consultez sans attendre un professionnel de santé.</span>
					<a
						:href="liens.enSavoirPlus"
						target="_blank"
					>
						En savoir plus
					</a>
				</div>
			</div>
		</div>
		<template v-if="selectedOption === 'kit'">
			<S_Information />
			<S_Tutorial />
			<S_Notice />
			<S_Condom />
			<S_Faq />
		</template>
		<template v-if="selectedOption === 'laboratoire'">
			<S_Labo />
			<S_Condom />
		</template>

		<FloatButton
			text="Commandez votre kit"
			class="home__floatButton"
			:selected-option="selectedOption"
		/>
	</div>
</template>

<script lang="ts">
	/* eslint-disable max-lines */
	/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import AppImage from '@/components/image/image.vue';
	import FloatButton from '@/components/button/floatButton.vue';
	import { IMAGES } from '@/constants/image';
	import S_Header from '@/components/home/sections/header/S_Header.vue';
	import S_Information from '@/components/home/sections/information/S_Information.vue';
	import S_Tutorial from '@/components/home/sections/tutorial/S_Tutorial.vue';
	import S_Notice from '@/components/home/sections/notice/S_Notice.vue';
	import S_Condom from '@/components/home/sections/condom/S_Condom.vue';
	import S_Faq from '@/components/home/sections/faq/S_Faq.vue';
	import S_Labo from '@/components/home/sections/labo/S_Labo.vue';
	//

	/** Home page */
	@Component({
		components : {
			S_Labo,
			S_Faq,
			S_Condom,
			S_Notice,
			S_Tutorial,
			S_Information,
			S_Header,// This version
			AppImage,
			FloatButton
		}
	})

	export default class Home extends Vue {
		selectedOption = 'laboratoire';
		get liens() {
			return {
				enSavoirPlus: 'https://questionsexualite.fr/trouver-un-professionnel'
			};
		}

		get imageUrl() {
			return {
				warningImage: IMAGES.WARNINGICON
			};
		}
	}
</script>
<style lang="scss" scoped>
@use '../theme/colors.scss' as themeColors;
@use '../theme/breakpoints.scss' as breakpoints;

.switch-container {
	user-select: none;
	background-color: themeColors.$WHITE;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 3rem;
	@media (max-width: breakpoints.$sm) {
		padding: 3rem 1rem;
	}
}

.switch {
	display: inline-flex;
	align-items: center;
	position: relative;
	background-color: #003580;
	border-radius: 4px;
	padding: 5px;
	width: 280px;

	@media (max-width: breakpoints.$sm) {
		width: 100%;
	}
}

/* Cacher les boutons radio */
.switch input[type="radio"] {
	display: none;
}

.option {
	flex: 1;
	text-align: center;
	cursor: pointer;
	color: #fff;
	font-weight: bold;
	z-index: 1;
	transition: color 0.3s;
	font-size: 18px;
	padding: 5px;
}

input#laboratoire:checked + label[for="laboratoire"],
input#kit:checked + label[for="kit"] {
	color: #003580; /* Couleur du texte pour l'option active */
}

.slider {
	position: absolute;
	top: 5px;
	bottom: 5px;
	left: 5px;
	width: 48%;
	background-color: #fff; /* Couleur de l'arrière-plan du slider */
	border-radius: 4px;
	transition: left 0.3s;
}

/* Positionner le slider en fonction de l'option sélectionnée */
input#kit:checked ~ .slider {
	left: 50%;
}

.home {
	background-color: white;
	padding-bottom: 15px;

	@media (max-width: breakpoints.$sm) {
		padding: 1rem;
	}

	&__floatButton {
		color: themeColors.$WHITE;
	}

	&__content {
		width: 700px;
		margin: auto;
	}
	&__alert {
		display: grid;
		grid-template-columns: auto 1fr auto;
		gap: 1rem;
		border-left: 4px solid themeColors.$PINK;
		border-radius: 2px;
		align-items: center;
		background-color: themeColors.$LIGHTPINK;
		padding:15px 15px;

		@media (max-width: breakpoints.$sm) {
			grid-template-columns: 1fr;
			gap: .4rem;
			padding: 10px 15px;
		}

		&__img {
			width: 24px;
			height: 24px;
		}

		p {
			font-size: .9rem;
			margin-bottom: 8px !important;
		}

		a {
			color: themeColors.$PINK;
		}

	}
}
</style>
