export const IMAGES = {
  BOY: 'boy1.png',
  GIRL: 'girl1.png',
  ISTPOST: 'logo_mtist.png',
  CHLAMYQUOI: 'chlamydia.png',
  NEWCHLAMYDIA: 'new-chlamydia.png',
  CHLAMYDIAE: 'chlamydiae.png',
  GONOQUOI: 'gonoquoi.png',
  GONOCOQUE: 'gonocoque.png',
  SEPARATOR: 'separator.png',
  PRESERVATIF: 'preservatif.png',
  STEP1: 'step1.png',
  STEP2: 'step2.png',
  STEP3: 'step3.png',
  ARROW: 'arrow.png',
  ARROWUP: 'arrowUp.png',
  ARROWRIGHT: 'arrowRight.png',
  PLUS: 'plus.png',
  MOINS: 'moins.png',
  LOUPE: 'loupe.png',
  MSA: 'msa.png',
  SPF: 'spf.png',
  RETURN: 'return.png',
  MAIN: 'main_bg.png',
  ARM: 'arm.png',
  PHONE: 'phone_bg.png',
  ENTER: 'enter.png',
  INFO_PRIMARY: 'info-primary.png',
  INFO_PRIMARY_FILL: 'info-primary-fill.png',
  INFO_ERROR: 'info-error.png',
  VERIF: 'verif.png',
  COORD: 'coord.png',
  VALID: 'icon.png',
  ALERTE: 'alerte.png',
  POUCE: 'pouce.png',
  LOCATION: 'location.png',
  EPROUVETTE: 'eprouvette.png',
  COMMANDE: 'commande.png',
  WARNINGICON: 'warning.png',
  INFOWOMEN: 'infoWomen.png',
  ARROW_PINK: 'arrow_pink.png',
  ARROW_GREEN: 'arrow_green.png',
  WOMAN_SYMBOL: 'woman-symbol.png',
  QUESTION_SEXUALITE: 'question-sexualite.png',
  LOGO_IST: 'logo_ist.png',
  ALERT_ENVELOPPE: 'alert-enveloppe.png',
  CONDOM: 'condom.png',
  FAQ_SEARCH: 'faq_search.png',
  PHONE_EMPTY: 'phone/phone_empty.svg',
  PHONE_ICON_ALERT: 'phone/phone_icon_alert.png',
  PHONE_ICON_TEXT: 'phone/phone_icon_text.svg',
  ARM_SVG: 'arm.svg',
  ICON_MANUAL_RED: 'icon_manual_red.svg',
  ICON_MANUAL_GENDER: 'icon_manual_gender.svg',
  ICON_MANUAL_ASK: 'icon_manual_ask.svg',
  ICON_MANUAL_GREEN: 'icon_manual_green.svg'
};
